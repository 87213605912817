import React, { useState, useEffect, useRef } from 'react';
import { ArrowUpCircle, Rocket, DollarSign, Brain } from 'lucide-react';
import logo from './assets/athena-buterin-logo.jpg';
import backgroundImage from './assets/background.jpg';
import bubbleImage from './assets/bubble.png';

// Updated Bubble component
const Bubble = ({ style, onPop }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPopping, setIsPopping] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTimeout(() => {
      setIsPopping(true);
      setTimeout(onPop, 300); // Call onPop after the popping animation
    }, 300); // Start popping after grow animation
  };

  return (
    <img
      src={bubbleImage}
      alt="bubble"
      className={`absolute rounded-full opacity-70 transition-all duration-300 ease-in-out
        ${isHovered ? 'scale-125' : ''}
        ${isPopping ? 'scale-150 opacity-0' : ''}
      `}
      style={style}
      onMouseEnter={handleMouseEnter}
    />
  );
};

// Updated Bubbles component
const Bubbles = () => {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const createBubble = () => {
      const size = Math.random() * 50 + 20;
      const bubble = {
        id: Date.now(),
        x: Math.random() * window.innerWidth,
        y: Math.random() * document.documentElement.scrollHeight,
        size: size,
        speed: Math.random() * 2 + 1,
      };
      setBubbles(prevBubbles => [...prevBubbles, bubble]);
    };

    const interval = setInterval(createBubble, 750);

    return () => clearInterval(interval);
  }, []);

  const handlePop = (id) => {
    setBubbles(prevBubbles => prevBubbles.filter(b => b.id !== id));
  };

  return (
    <>
      {bubbles.map(bubble => (
        <Bubble
          key={bubble.id}
          style={{
            left: `${bubble.x}px`,
            top: `${bubble.y}px`,
            width: `${bubble.size}px`,
            height: `${bubble.size}px`,
            animation: `rise ${5 / bubble.speed}s linear`,
          }}
          onPop={() => handlePop(bubble.id)}
        />
      ))}
    </>
  );
};


const App = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [scrollY, setScrollY] = useState(0);
  const parallaxRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideCount = 3;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slideCount);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  const slidesStyle = {
    transform: `translateX(-${currentSlide * 100}%)`,
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-200 via-purple-300 to-indigo-300 text-gray-800 font-serif" style={{ fontFamily: "'Trajan Pro', 'Times New Roman', serif" }}>
      <Bubbles /> {/* Add the Bubbles component here */}
      <header className="fixed top-0 left-0 right-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-lg z-50">
        <nav className="container mx-auto px-4 py-4">
          <ul className="flex justify-around">
            {['home', 'about', 'roadmap', 'how-to-buy'].map((section) => (
              <li key={section}>
                <button
                  onClick={() => scrollToSection(section)}
                  className={`text-lg font-bold uppercase transition-all duration-300 ${
                    activeSection === section ? 'text-yellow-500 scale-110' : 'text-purple-600 hover:text-yellow-500 hover:scale-110'
                  }`}
                >
                  {section}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Improved Slider */}
      <div className="pt-16"> {/* Add padding-top to push content below fixed header */}
        <div className="slider-container relative overflow-hidden" aria-live="polite" aria-atomic="true" style={{ height: '50px' }}>
          <div className="slides absolute top-0 left-0 w-[300%] flex transition-transform duration-1000 ease-in-out h-full" style={slidesStyle}>
            <div className="slide w-1/3 flex items-center justify-center bg-purple-400">
              <h2 className="text-white text-4xl">PREPARE YOURSELF FOR THE AIRDROP --- HODL METIS --- </h2>
            </div>
            <div className="slide w-1/3 flex items-center justify-center bg-pink-300">
              <h2 className="text-white text-4xl">PREPARE YOURSELF FOR THE AIRDROP --- HODL METIS --- </h2>
            </div>
            <div className="slide w-1/3 flex items-center justify-center bg-yellow-300">
              <h2 className="text-white text-4xl">PREPARE YOURSELF FOR THE AIRDROP --- HODL METIS --- </h2>
            </div>
          </div>
        </div>
      </div>

      <main className="pt-4">
        <section id="home" className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <img src={logo} alt="AthenaButerin Logo" className="mx-auto mb-8 rounded-full border-4 border-yellow-400 animate-bounce" style={{width: '200px', height: '200px'}} />
            <h1 className="text-6xl font-bold mb-4 text-purple-600">AthenaButerin</h1>
            <p className="text-3xl mb-8 text-purple-900">The meme coin that's here for the people!</p>
            <button className="bg-yellow-400 text-purple-700 px-8 py-3 rounded-full text-xl font-bold hover:bg-yellow-300 transition duration-300 transform hover:scale-110">
              Be sure you've bridged to METIS
            </button>
          </div>
        </section>

        <section id="about" className="min-h-screen flex items-center justify-center py-20 relative overflow-hidden" ref={parallaxRef}>
          <div 
            className="absolute inset-0 z-0" 
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: '100% 100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              height: '100%',
              width: '100%',
            }}
          ></div>
          <div className="max-w-4xl mx-auto text-center px-4 relative z-10 bg-purple-700 bg-opacity-60 rounded-lg shadow-xl p-8">
            <h2 className="text-5xl font-bold mb-8 text-yellow-400 animate-bounce" style={{ fontFamily: "'Comic Sans MS', cursive, sans-serif" }}>About AthenaButerin</h2>
            <div className="space-y-6 text-lg" style={{ fontFamily: "'Comic Sans MS', cursive, sans-serif", color: "#FFF" }}>
              <p className="animate-fade-in-up">Are you ready to be part of the next big success story on METIS? AthenaButerin is here to democratize wealth through the power of memes. Our vision is to empower the community and make a lasting impact by turning lucky METIS holders into millionaires.</p>
              <p className="text-2xl font-bold text-yellow-400 animate-pulse">
                🚨 AthenaButerin is the pioneering MEME token on METIS, designed for the true innovators of the METIS ecosystem. Don't miss out on this unique opportunity to be part of something groundbreaking.
              </p>
              <p className="animate-fade-in-left">Our strategy is both simple and powerful: make the community wealthy. By incentivizing early holders and active participants, we aim to drive engagement and growth within the METIS network.</p>
              <p className="animate-fade-in-right">We recognize the complexities involved in accessing METIS, often requiring multiple steps and bridges. That's why ATHENA is crafted to reward those who have truly committed and navigated these challenges. ATHENA is more than just a token; it's a symbol of perseverance and success.</p>
              <p className="animate-fade-in-up">
                The Wisdom of Metis: Metis symbolizes wisdom, and we embrace this concept by integrating the number 67. This number reflects the idea of sharing wisdom (6 for sharing, 7 for wisdom). Keep this number in mind as you join us on this journey.
              </p>
              <p className="animate-fade-in-up"> Oh, and here's the kicker: Metis, in Greek mythology, was a straight-up Titaness—wise, strategic, always one step ahead. She was Zeus's first wife and Athena's mother. Now, METIS the network is bringing that same energy. And guess what? Natalia Ameline, the mom of none other than Vitalik Buterin, is in the mix. When she moves, people pay attention. She's been key in growing METIS, pushing education with CryptoChicks, and driving METIS's rise as a top Layer 2.</p>
              <p className="animate-fade-in-up">With ATHENA, we aim to simplify the entry into METIS and attract new users to the ecosystem. This MEME coin is not just an investment; it's a gateway to a vibrant and expanding network. Join us in making a significant impact and shaping the future of METIS. Let's create something extraordinary together! 🚀</p>
            </div>
          </div>
        </section>

        <section id="roadmap" className="min-h-screen flex items-center justify-center py-20 bg-gradient-to-r from-yellow-200 via-pink-200 to-purple-200">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-5xl font-bold mb-12 text-center text-purple-700">Roadmap to Olympus</h2>
            <div className="space-y-12">
              {[
                { phase: "Phase 1: Meme Genesis", description: "Launch with a MASSIVE AIRDROP and build the epic meme community", icon: <Rocket /> },
                { phase: "Phase 2: Meme Explosion", description: "Major exchange listings and viral marketing campaigns", icon: <DollarSign /> },
                { phase: "Phase 3: Meme Evolution", description: "STAKE your MEME to earn secound round of AIRDROP", icon: <Brain /> },
                { phase: "Phase 4: Meme Domination", description: "ENJOY", icon: <ArrowUpCircle /> },
              ].map((item, index) => (
                <div key={index} className="flex items-center space-x-4 animate-fade-in-left">
                  <div className="w-16 h-16 bg-gradient-to-r from-purple-400 to-pink-500 rounded-full flex items-center justify-center text-white">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-indigo-700">{item.phase}</h3>
                    <p className="text-lg text-purple-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="how-to-buy" className="min-h-screen flex items-center justify-center py-20">
          <div className="max-w-3xl mx-auto text-center px-4">
            <h2 className="text-5xl font-bold mb-12 text-purple-700">How to Buy AthenaButerin</h2>
            <ol className="space-y-8 text-left">
              {[
                "Set up a crypto wallet (MetaMask recommended)",
                "Buy Ethereum (ETH) from a major exchange",
                "Bridge your ETH to METIS network",
                "Visit our decentralized exchange partner on METIS(SOON)",
                "Swap for AthenaButerin tokens(SOON)",
                "HODL and watch your investment go to the moon!(SOON) 🚀"
              ].map((step, index) => (
                <li key={index} className="flex items-center space-x-4 animate-fade-in-right">
                  <span className="w-10 h-10 bg-gradient-to-r from-yellow-400 to-orange-500 rounded-full flex items-center justify-center text-white font-bold text-lg">
                    {index + 1}
                  </span>
                  <span className="text-xl text-purple-600">{step}</span>
                </li>
              ))}
            </ol>
            <div className="mt-8">
              <button className="bg-yellow-400 text-purple-700 px-8 py-3 rounded-full text-xl font-bold hover:bg-yellow-300 transition duration-300 transform hover:scale-110">
                Be sure you've bridged to METIS
              </button>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <p className="text-lg">© 2024 AthenaButerin. All ATHENA HAS BEEN Reserved.</p>
          <p className="text-sm text-gray-400">Built with memes and dreams on the METIS network.</p>
        </div>
      </footer>

      <style jsx>{`
        @keyframes rise {
          0% {
            transform: translateY(0) scale(1);
            opacity: 0.7;
          }
          100% {
            transform: translateY(-100vh) scale(0);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default App;